var isMobile = new Object();
isMobile.Android = function () {
	return navigator.userAgent.match(/Android/i);
};

isMobile.BlackBerry = function () {
	return navigator.userAgent.match(/BlackBerry/i);
};
isMobile.iOS = function () {
	return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

isMobile.iPad = function () {
	return navigator.userAgent.match(/iPad/i);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera Mini/i);
};
isMobile.Windows = function () {
	return navigator.userAgent.match(/IEMobile/i);
};

isMobile.Firefox = function () {
	return navigator.userAgent.match(/Firefox/gi);
};
isMobile.InternetExplorer = function () {
	return navigator.userAgent.match(/MSIE/gi);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera/gi);
};
isMobile.Safari = function () {
	return navigator.userAgent.match(/Safari/gi);
};
isMobile.any = function () {
	return (
		isMobile.Android() ||
		isMobile.BlackBerry() ||
		isMobile.iOS() ||
		isMobile.Opera() ||
		isMobile.Windows()
	);
};
if (isMobile.any() && isMobile.iPad() == null) {
	//return;
}

var ua = window.navigator.userAgent;
var is_ie = /MSIE|Trident/.test(ua);

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

var winWT = $(window).innerWidth();
var winHT = $(window).innerHeight();

$(window).on("load", function() {
	$("#loader").fadeOut(500);
});

$(document).ready(function () {
	//alert( winWT + "&&" + winHT);
	
	var disableScrolling = function(){
		var x=window.scrollX;
		var y=window.scrollY;
		window.onscroll=function(){window.scrollTo(x, y);};
	}
	var enableScrolling = function(){
		window.onscroll=function(){};
	}
	function onResize(){
		if(isMobile.any() && $("input").is(":focus")==true){
			return;
		}
		winWT = $(window).innerWidth();
		winHT = $(window).innerHeight();
		//$("section").css({minHeight:winHT});

		if (winWT <= 601) {
			$(".banner-area .inverted-border-radius .banner-area").css({minHeight:winHT});
		}
	}
	
	$(window).resize(onResize)
	onResize();

	if(winWT < 601){
		function onOrientationChange() {
			if (window.orientation == 90 || window.orientation == -90) {
				$(".rotate-msg").css("display", "block");
			} else {
				$(".rotate-msg").css("display", "none");
			}
		}
		window.addEventListener("orientationchange", onOrientationChange, false);
		onOrientationChange();
	}

	$(function() {
		AOS.init({once: true});
	});

	$(document).on('click', '.btn-apply', function (event) {
		event.preventDefault();
		$('html, body').animate({
			scrollTop: $("#formApplyNow").offset().top
		}, 500);
	});
	   
});